// @import '~bootstrap/dist/css/bootstrap.min.css';
// @import './style.css';

$primary-green: #1ba496;
$secondary-green: #126960;
$primary-black: #222222;
$red: #dc3545;
$sticky-top-height: 60px;
$carousel-height: auto;
$carousel-height-lg: 600px;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-black;
}

a {
  text-decoration: none;
  color: $primary-black;
  &:hover {
    text-decoration: none;
    color: $primary-green;
  }
}

.active-scroll-spy {
  text-decoration: none;
  color: $primary-green;
  border-bottom: 3px solid $primary-green;

  a {
    color: $primary-green !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Manrope', sans-serif !important;
}

@mixin disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled {
  @include disabled;
}

.underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.capitalize {
  text-transform: capitalize;
}

.green {
  background-color: $primary-green;
  // background-color: $primary-green !important;
  color: #fff;
  &.btn {
    border-color: $primary-green;
  }

  &:hover {
    background-color: $secondary-green;
    color: #fff;
  }
}
.green2 {
  background-color: $secondary-green;
  color: #fff;

  &.btn {
    border-color: $secondary-green;
  }

  &:hover {
    background-color: $primary-green;
    color: #fff;
  }
}
.text-green {
  color: $primary-green;
  &:hover {
    color: $secondary-green;
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $primary-green;
}

.pagination button {
  &:disabled {
    @include disabled;
  }
  outline-color: $primary-green;
}

button {
  .active,
  .selected {
    &:not(:disabled) {
      background-color: $primary-green;
    }
  }
}

#about {
  background: #f7f7f7;
}

.step-counter {
  position: absolute;
  font-size: 2.2rem;
  font-weight: bold;
  left: 10px;
}

.rounded-1 {
  border-radius: 0.375rem;
}

.rounded-left-1 {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.rounded-right-1 {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.text-small {
  font-size: 0.8rem;
}

.all-listing-wrapper .container {
  .action-btn {
    background-color: transparent;
  }
  @media (max-width: 767.98px) {
    max-width: 680px;
  }
  .atbd_listing_grid .grid-amount-info {
    background-color: #060101d9;
    position: absolute;
    bottom: 5px;
    left: 5px;
    z-index: 2;
  }
}

.atbd_listing_data_list ul li .desc {
  line-height: 1.2rem;
}

.atbd_single_listing:hover {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.12);
}

.atbd_listing_thumbnail_area {
  .carousel,
  .carousel-inner,
  .carousel-item,
  img {
    height: 100%;
  }
  .carousel-item img {
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.listing-filter-sidebar {
  left: 0;
  .default-ad-search {
    border: none;
    padding: 1rem;
  }
}

button.disabled {
  &:not(.current):not(.active):not(.gbtn) {
    @include disabled;
  }
  &.active,
  &.current {
    cursor: not-allowed;
  }
}

.pointer {
  cursor: pointer;
}

.input-range__track--active {
  background: $primary-green !important;
}
.input-range__slider {
  background: $primary-green !important;
  border-color: $primary-green !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $primary-green;
  background-color: $primary-green;
}
// .custom-control-label::before,
// .custom-control-label::after {
//   width: 20px;
//   height: 20px;
// }
// .custom-control-label::after {
//   top: calc(0.25rem + 4px);
// }

nav.sticky-top {
  background-color: #fff;
  height: $sticky-top-height;
  border-bottom: 1px solid #ebebeb;

  .list-inline-item {
    &:hover {
      border-bottom: 3px solid $primary-green;
      a {
        color: $primary-green;
      }
    }
    a {
      font-weight: 700;
      color: black;
      text-decoration: none;
    }
  }
}

.atbdp-accordion a {
  text-decoration: underline;
}

.directory_open_hours {
  padding: 1.33333rem 0.5rem;
  text-align: center;
  .atbd_open {
    padding-left: 1.33333rem;
    padding-right: 1.33333rem;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    &:not(.react-datepicker__time-list-item--disabled) {
      background-color: $secondary-green !important;
      outline: none;
    }
    &.react-datepicker__time-list-item--disabled {
      background: none !important;
    }
  }

  .react-datepicker__day {
    font-weight: bold;
  }

  .react-datepicker__time-list-item {
    margin-bottom: 0;
  }

  .react-datepicker__day--today {
    background-color: initial !important;
  }

  .react-datepicker__time-list-item--disabled {
    // display: none;
  }

  @media (max-width: 1199.98px) {
    & ul > div:first-of-type {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .react-datepicker {
        width: 100%;

        .react-datepicker__navigation--next--with-time:not(
            .react-datepicker__navigation--next--with-today-button
          ) {
          right: 0px;
        }
        .react-datepicker__month-container {
          float: none;
        }
        .react-datepicker__time-container {
          float: none;
          width: 100%;
          border: 0;
          .react-datepicker__time-box {
            width: 100%;
            li {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}

.schedule-modal {
  button {
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      pointer-events: none;
    }
  }
  @mixin inspection-buttons {
    &:not(:disabled) {
      &:hover {
        border-color: $secondary-green !important;
        background-color: #ddd;
      }
      &:active {
        background-color: $primary-green;
        color: #fff;
      }
      &.selected {
        color: #fff;
        background-color: $secondary-green;
      }
    }
  }
  .modal-body {
    .table-wrapper {
      max-height: 70vh;
      overflow: auto;

      th {
        background-color: #fff;
        box-shadow: 7px 6px 9px -1px #a5a599;
      }
      td > button {
        @include inspection-buttons;
      }
    }

    .inspection-type button {
      @include inspection-buttons;
    }

    .table-pager button {
      font-size: 1.2rem;
      &:hover {
        border-color: $secondary-green !important;
        background-color: #ddd;
      }
    }
  }
}

// @media

/* TEMPLATE OVERWRITES */
.menu-area.menu--light .top-menu-area,
.mainmenu-wrapper {
  border: none;
}

.bg_image_holder {
  // background-image: url('https://www.demoapus-wp1.com/homeo/wp-content/uploads/2020/03/slider1.jpg') !important;
  opacity: 0.9;
}

.overlay.overlay--dark:before {
  background-color: #1d293e;
  opacity: 0.6;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.top-menu-area .menu-fullwidth {
  .logo-wrapper {
    border-right: none;
    // override
    @media (max-width: 991.98px) {
      position: initial;
      left: initial;
      transform: none;
    }
    .logo-top a {
      padding: initial;
    }
  }

  .search-wrapper {
    // override
    @media (max-width: 576px) {
      min-width: auto;
    }
  }
}

.menu-area.menu--light .mainmenu__menu .navbar-nav > li > a.active {
  color: $primary-green;
}

.section-padding-two,
.section-bg {
  background: #f7f7f7;
}

.how-work.col-lg-4.col-sm-6 {
  min-height: 250px;
  background: white;
  max-width: 30%;
}

.category-place-single figure a:after {
  background: linear-gradient(
    90deg,
    rgba(27, 164, 150, 1) 35%,
    rgba(30, 51, 55, 1) 100%
  );
}

.section-padding {
  padding: 3.06667rem 0 4.66667rem;
}

.menu-area .author-area {
  margin-right: 10rem;
  .author__access_area li.has_dropdown {
    .dropdown-menu {
      display: block;
      font-weight: initial;
      padding: 1rem 0;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
      z-index: 10;
      li {
        margin-right: 0;
      }
    }
    &:hover {
      .dropdown-menu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.menu-area
  .mainmenu__menu
  .navbar-nav
  > li.has_dropdown
  .dropdown-toggle:after {
  font-size: rem;
  content: '';
  padding: 0px;
}

.directory_content_area
  .search_form
  .atbd_seach_fields_wrapper
  .single_search_field.search_category:before,
.directory_content_area
  .search_form
  .atbd_seach_fields_wrapper
  .single_search_field.search_location:before {
  content: '';
}

.single_search_field .ref__input {
  height: 100% !important;
  min-height: 38px;
}

.atbd_listing_data_list ul li {
  margin-bottom: 0.53333rem;
}

.all-listing-wrapper .atbd_generic_header_title_text {
  font-size: 0.9rem;
}

.btn-gradient {
  z-index: inherit;
}

.badge {
  line-height: initial;
}

@media (max-width: 400px) {
  .all-listing-wrapper .atbd_generic_header .btn-toolbar {
    margin-top: 0;
  }
}

@media (max-width: 992px) {
  .atbdb_content_module_contents .video-wrapper {
    height: auto;
  }
  .atbdb_content_module_contents .video-wrapper video {
    width: 100%;
  }

  .atbdb_content_module_contents .video-wrapper figcaption {
    width: 100%;
    height: auto;
    display: flex;
  }

  .atbdb_content_module_contents .video-wrapper figcaption button {
    max-width: 200px;
  }
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  // &:before {
  //   padding-top: 55%;
  //   content: '';
  //   display: block;
  // }
  iframe {
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  figcaption button.video-select {
    border-right: 5px solid $secondary-green;
  }

  @media (min-width: 992px) {
    figcaption button.video-select {
      border-right: none;
      border-bottom: 5px solid $secondary-green;
    }
  }
}

.icons_mapper_container {
  font-size: 26px;

  img {
    width: 26px;
    height: 26px;
  }
}

.atbd_content_module .atbdb_content_module_contents {
  padding: 1rem;
  padding-left: 0;
}
.atbd_area_title h4,
.atbd_widget_title h4 {
  font-weight: 700;
}

.detail-filter.widget-body {
  padding: 0;

  .search-area {
    width: 100%;
    margin-top: 1em;
    padding: 1.2rem;
    border: none !important;
    padding-top: 0;
    overflow: auto;
  }
}

.login-modal {
  z-index: 3000; // highest precedence
}

.bank-modal {
  z-index: 2500; // second highest precedence
}

.listing-details-wrapper {
  z-index: 1021; // higher than .sticky-top. Remove if we stop using sticky-stop
}

.global-banner {
  z-index: 1022; // higher than .sticky-top. Remove if we stop using sticky-stop
}

.mainmenu-wrapper {
  z-index: 1023; // mobile menu - higher than wrapper and everything else. Remove if we stop using sticky-stop
}

.listing-header-zee {
  background: #fff;
  z-index: 1021; // higher than sticky-top on filter sidebar
}

.bm-overlay {
  z-index: 1100 !important;
}

.listing-details-wrapper {
  height: $carousel-height;
  .carousel-item,
  .keen-item,
  .keen-item img {
    height: $carousel-height;
  }

  @media (min-width: 1024px) {
    height: $carousel-height-lg;

    .carousel-item,
    .keen-item,
    .keen-item img {
      height: $carousel-height-lg;
    }
  }

  &-mobile-gallery {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #1d293e;
      opacity: 0.1;
      pointer-events: none;
      z-index: 10;
    }

    @media (min-width: 1024px) {
      display: none;
    }
  }

  &-desktop-gallery {
    height: 100%;
    display: none;

    @media (min-width: 1024px) {
      display: block;
    }
  }

  .carousel img {
    width: 100%;
    height: 100%;
  }
  .listing-info {
    &.content_above {
      position: absolute;
      bottom: 12%;
      left: 0;
      width: 100%;
      height: initial;
      z-index: 15;

      @media (min-width: 1024px) {
        bottom: 5%;
      }
    }
  }

  .atbd_action.active,
  .atbd_action .action_button:focus {
    border: none !important;
  }
}

.atbd_action {
  .action_button {
    // margin-top: -.25rem;
    // margin-bottom: -.25rem;
    &:focus {
      box-shadow: none;
    }
  }
  border: none;
  .dropdown-toggle::after {
    @media (max-width: 767.98px) {
      display: none;
    }
  }
}

.dropdown-item.active {
  background-color: $primary-green;
}
.dropdown-item:active {
  background-color: $secondary-green;
}

.atbd_listing_info {
  overflow: hidden;
  .atbd_listing_title {
    font-size: 1.2rem;
    text-overflow: ellipsis;
  }
}

.atbd_save .la {
  font-size: 1.2rem;
}

.listing-amount-popover .breakdown-list {
  padding-left: 0.5rem;
}

.darken {
  background: rgba(0, 0, 0, 0.5);
}

.flatmate-question-card {
  height: 550px;

  &-options {
    scrollbar-width: thin;
    scrollbar-color: #c4c3ca transparent;

    &::-webkit-scrollbar {
      border-radius: 8px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #c4c3ca;
      border-radius: 8px;
    }
  }
}

.directory_content_area .search_form .atbd_seach_fields_wrapper {
  padding: 2rem 1rem;
}

.directory_content_area
  .search_form
  .atbd_seach_fields_wrapper
  .atbd_submit_btn {
  flex: unset;
}

.listings--gallery--container {
  width: 100%;
  height: 100%;
  padding: 2em;
  overflow: hidden;
  background: #fff;
  position: relative;

  .listings--gallery {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    height: 100%;
    display: grid;
    gap: 0.2rem;
    border-radius: 10px;
    overflow: hidden;

    .listings--gallery--item {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      position: relative;
      cursor: pointer;

      &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #1d293e;
        opacity: 0.4;
        pointer-events: none;
      }

      &:hover {
        &::before {
          display: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &.listings--gallery--lg {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;

      .listings--gallery--item {
        &:first-child {
          grid-column: 1 / span 2;
          grid-row: 1 / span 2;
        }

        &:nth-child(2) {
          grid-column: 3 / span 1;
          grid-row: 1 / span 1;
        }

        &:nth-child(3) {
          grid-column: 4 / span 1;
          grid-row: 1 / span 1;
        }

        &:nth-child(4) {
          grid-column: 3 / span 1;
          grid-row: 2 / span 1;
        }

        &:nth-child(5) {
          grid-column: 4 / span 1;
          grid-row: 2 / span 1;
        }
      }
    }

    &.listings--gallery--md {
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 1fr 1fr;

      .listings--gallery--item {
        &:first-child {
          grid-column: 1 / span 3;
          grid-row: 1 / span 2;
        }

        &:nth-child(2) {
          grid-column: 4 / span 2;
          grid-row: 1 / span 1;
        }

        &:nth-child(3) {
          grid-column: 4 / span 2;
          grid-row: 2 / span 1;
        }
      }
    }

    &.listings--gallery--sm {
      display: flex;
    }
  }
}

.listings--gallery--btn {
  position: absolute;
  right: 5%;
  top: 5%;
  z-index: 20;
  font-weight: bold;

  @media (min-width: 1024px) {
    top: 10%;
    right: 12%;
  }
}

.slider--thumbnail {
  position: relative;
  width: 100%;
  display: flex !important;
  list-style: none !important;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  column-gap: 5px;
  z-index: 15;
  background: #fff;
  padding: 0.5em 0;
  overflow-x: auto;

  li {
    width: 80px;
  }

  &--img {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    width: 100%;
    object-fit: cover;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}
